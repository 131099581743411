import React, { Fragment } from 'react';
import Navbar from '../../components/SaveTheDate/Navbar';
import Hero3 from '../../components/SaveTheDate/hero3';
import WeddingDate from '../../components/SaveTheDate/WeddingDate';
import CoupleSection from '../../components/SaveTheDate/CoupleSection';
import EventSection from '../../components/SaveTheDate/EventSection';
import Footer from '../../components/SaveTheDate/Footer';


const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero3 />
            <WeddingDate pbClass={'pb-0'} WeddingDate={'12/14/2024'}/>
            <CoupleSection />
            <EventSection/>
            <Footer footerClass={''}/>            
        </Fragment>
    )
};

export default HomePage;