import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from './SectionTitle'
import sImg2 from '../../images/SaveTheDate/iglesia.jpg'
import sImg3 from '../../images/SaveTheDate/salon.jpg'
import LocationMap from './EventSection/Modal'


const Events = [
    {
        Simg: sImg2,
        title: 'Ceremonia',
        li1: 'Sábado, 14/Diciembre/2024 1:30 PM – 2:30 PM',
        li2: 'C. Macedonio Alcalá s/n, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61026.40722497136!2d-96.72279979999999!3d17.0652919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce844bd6a2df83%3A0x1aede71d26266078!2sTemplo%20de%20Santo%20Domingo%20de%20Guzm%C3%A1n!5e0!3m2!1ses-419!2smx!4v1725765585705!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
        animation: '1400',
    },
    {
        Simg: sImg3,
        title: 'Recepción y Fiesta!',
        li1: 'Sábado, 14/Diciembre/2024 3:30 PM',
        li2: 'Casa Calenda Eventos, San Agustin Yatareni, 68290 Oaxaca, Oax.',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3813.9379491572536!2d-96.66556962404988!3d17.075687611708208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c7256d0e366131%3A0x10a684ac67545991!2sCasa%20Calenda%20Eventos!5e0!3m2!1ses-419!2smx!4v1725765640369!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
        animation: '1600',
    },

]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section-s2 section-padding pt-0" id="event">
            <div className="container">
                <SectionTitle subTitle={'Nuestra Boda'} MainTitle={'Cuándo & Dónde'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item" >
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li><LocationMap Location={event.location}/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;